import api, {
  ListResponse,
  MutationResponse,
  PaginationParam,
  StrapiFile,
} from './base';

export interface PortfolioParams
  extends Partial<Omit<PortfolioAttributes, 'images'>> {
  images: number[];
}
export interface PortfolioAttributes {
  images: {
    data: { id: number; attributes: StrapiFile }[] | null;
  };
  name: string;
  description: string;
  link1: string;
  linkText1: string;
  linkText2: string;
  link2: string;
}

export interface Portfolio {
  id: number;
  attributes: PortfolioAttributes;
}

export const getPortfoliosApi = async ({ page, pageSize }: PaginationParam) =>
  api.get(
    `/portfolios/get-user-portfolios?populate[images]=*&sort[0]=createdAt:desc&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
  ) as Promise<ListResponse<Portfolio>>;

export const getPortfoliosByVendorIdApi = async ({
  page,
  pageSize,
  vendorId,
}: PaginationParam & { vendorId: number }) =>
  api.get(
    `/portfolios/get-user-portfolios/${vendorId}?populate[images]=*&sort[0]=createdAt:desc&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
  ) as Promise<ListResponse<Portfolio>>;

export const getPortfolioApi = async (id: number) =>
  api.get(`/portfolios/${id}?populate[images]=*`) as Promise<
    MutationResponse<Portfolio>
  >;

export const createPortfolioApi = async (params: PortfolioParams) =>
  api.post('/portfolios', { data: params }) as Promise<
    MutationResponse<Portfolio>
  >;

export const updatePortfolioApi = async (id: number, data: PortfolioParams) =>
  api.put(`/portfolios/${id}`, { data }) as Promise<
    MutationResponse<Portfolio>
  >;
