import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setAccessToken } from 'apis/base';
import { loginApi, LoginParams } from 'apis/auth';
import { AuthState } from './type';

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (params: LoginParams) => {
    const { user, jwt } = await loginApi(params);
    setAccessToken(jwt);
    return user;
  }
);

export const loginReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(loginThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    state.isAuthenticated = true;
    state.openLoginModal = false;
    state.user = payload;
  });
  builder.addCase(loginThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
