import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationParam } from 'apis/base';
import { getMonthlyReportsApi, MonthlyReportFilterParams } from 'apis/report';
import { ReportState } from '../report/type';

export const getMonthlyReportsThunk = createAsyncThunk(
  'report/getMonthlyReports',
  async (param: MonthlyReportFilterParams) => {
    const response = await getMonthlyReportsApi(param);
    return response;
  }
);

export const getMonthlyReportsReducer = (
  builder: ActionReducerMapBuilder<ReportState>
): void => {
  builder.addCase(getMonthlyReportsThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getMonthlyReportsThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.monthlyReports = payload;
  });
  builder.addCase(getMonthlyReportsThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
