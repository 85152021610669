import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { MonthlyReportParams, submitMonthlyReportApi } from 'apis/project';
import { ProjectState } from './type';

export const submitMonthlyReportThunk = createAsyncThunk(
  'project/submitMonthlyReport',
  async ({
    projectId,
    params,
  }: {
    projectId: number;
    params: MonthlyReportParams;
  }) => {
    const response = await submitMonthlyReportApi(projectId, params);
    console.log(response);
    return response;
  }
);

export const submitMonthlyReportReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(submitMonthlyReportThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(submitMonthlyReportThunk.fulfilled, (state) => {
    state.isProcessing = false;
  });
  builder.addCase(submitMonthlyReportThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
