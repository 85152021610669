import api, { ListResponse, MutationResponse } from './base';

export interface ServicePriceRange {
  priceLevel1Start: string;
  priceLevel1End: string;
  priceLevel2Start: string;
  priceLevel2End: string;
  priceLevel3Start: string;
  priceLevel3End: string;
  priceLevel4Start: string;
  priceLevel4End: string;
}

export interface Service {
  id: number;
  attributes: {
    name: string;
    popular: boolean;
    description?: string;
    priceRange?: ServicePriceRange;
  };
}

export interface CreateServiceParam {
  name: string;
}

// export const getServicesApi = async () =>
//   api.get('/services') as Promise<ListResponse<Service>>;

export const getPopularServicesApi = async () =>
  api.post('/services/all?filters[popular][$eq]=true') as Promise<
    ListResponse<Service>
  >;

export const getAllServicesApi = async () =>
  api.post('/services/all?populate[priceRange]=*') as Promise<
    ListResponse<Service>
  >;

export const createServiceApi = async (params: CreateServiceParam) =>
  api.post('/services', { data: params }) as Promise<MutationResponse<Service>>;
