import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setAccessToken } from 'apis/base';
import { AuthState } from './type';
import { signupReducer } from './signupReducer';
import { initializeReducer } from './initializeReducer';
import { confirmEmailReducer } from './confirmEmailReducer';
import { loginReducer } from './loginReducer';
import { updateProfileReducer } from './updateProfileReducer';
import { updateRatesReducer } from './updateRatesReducer';
import { sendEmailConfirmationReducer } from './sendEmailConfirmationReducer';
import { forgotPasswordReducer } from './forgotPasswordReducer';
import { resetPasswordReducer } from './resetPasswordReducer';
import { checkEmailExistedReducer } from './checkEmailExistedReducer';

export const initialState: AuthState = {
  isLoading: false,
  isProcessing: false,
  isAuthenticated: false,
  isInitialized: false,
  openSignupModal: false,
  openLoginModal: false,
  openForgotPasswordModal: false,
  openResetPasswordModal: false,
  user: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleSignupModal(state, { payload }: PayloadAction<boolean>) {
      state.openSignupModal = payload;
      state.openLoginModal = false;
      state.openForgotPasswordModal = false;
      state.openResetPasswordModal = false;
      state.error = undefined;
    },
    toggleLoginModal(state, { payload }: PayloadAction<boolean>) {
      state.openSignupModal = false;
      state.openLoginModal = payload;
      state.openForgotPasswordModal = false;
      state.openResetPasswordModal = false;
      state.error = undefined;
    },
    toggleForgotPasswordModal(state, { payload }: PayloadAction<boolean>) {
      state.openSignupModal = false;
      state.openLoginModal = false;
      state.openForgotPasswordModal = payload;
      state.openResetPasswordModal = false;
      state.error = undefined;
    },
    toggleResetPasswordModal(state, { payload }: PayloadAction<boolean>) {
      state.openSignupModal = false;
      state.openLoginModal = false;
      state.openForgotPasswordModal = false;
      state.openResetPasswordModal = payload;
      state.error = undefined;
    },

    logout(state) {
      setAccessToken();
      state.isAuthenticated = false;
      state.user = undefined;
      state.error = undefined;
    },

    resetError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    signupReducer(builder);
    initializeReducer(builder);
    confirmEmailReducer(builder);
    loginReducer(builder);
    updateProfileReducer(builder);
    updateRatesReducer(builder);
    sendEmailConfirmationReducer(builder);
    forgotPasswordReducer(builder);
    resetPasswordReducer(builder);
    checkEmailExistedReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
