import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setAccessToken } from 'apis/base';
import { confirmEmailApi } from 'apis/auth';
import { AuthState } from './type';

export const confirmEmailThunk = createAsyncThunk(
  'auth/confirmEmail',
  async (confirmation: string) => {
    const { user, jwt } = await confirmEmailApi(confirmation);
    setAccessToken(jwt);
    return user;
  }
);

export const confirmEmailReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(confirmEmailThunk.pending, (state, action) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(confirmEmailThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    state.isAuthenticated = true;
    state.user = payload;
  });
  builder.addCase(confirmEmailThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.isAuthenticated = false;
    state.error = error.message;
  });
};
