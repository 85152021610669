import api, { MutationResponse } from './base';

export interface Skill {
  id: number;
  attributes: { name: string };
}

export interface CreateSkillParams {
  name: string;
}

export const createSkillApi = async (params: CreateSkillParams) =>
  api.post('/skills', { data: params }) as Promise<MutationResponse<Skill>>;
