import { createSlice } from '@reduxjs/toolkit';
import { PortfolioState } from './type';
import { getPortfoliosReducer } from './getPortfoliosReducer';
import { getPortfolioReducer } from './getPortfolioReducer';
import { createPortfolioReducer } from './createPortfolioReducer';
import { updatePortfolioReducer } from './updatePortfolioReducer';

export const initialState: PortfolioState = {
  isLoading: false,
  isProcessing: false,
  portfolios: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 1,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: undefined,
};

const slice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getPortfoliosReducer(builder);
    createPortfolioReducer(builder);
    updatePortfolioReducer(builder);
    getPortfolioReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
