import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getMatchedVendorApi } from 'apis/matchedVendor';
import { ProjectState } from './type';

export const getMatchedVendorThunk = createAsyncThunk(
  'vendor/getMatchedVendor',
  async ({ vendorId, projectId }: { vendorId: number; projectId: number }) => {
    const venderDetail = await getMatchedVendorApi(vendorId, projectId);
    return venderDetail;
  }
);

export const getMatchedVendorReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(getMatchedVendorThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
    state.matchedVendor = undefined;
  });
  builder.addCase(getMatchedVendorThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.matchedVendor = payload;
  });
  builder.addCase(getMatchedVendorThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
