import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { updateProfileApi, UpdateProfileParams } from 'apis/auth';
import { AuthState } from './type';

// https://redux-toolkit.js.org/api/createAsyncThunk
// a failed request or error in a thunk will never return a rejected promise
// If your component needs to know if the request failed, use .unwrap
// by default reject error will be populate in action.error
// Doing return rejectWithValue(errorPayload) will cause the rejected action to use that value as action.payload
export const updateProfileThunk = createAsyncThunk(
  'auth/updateProfile',
  async (parmas: UpdateProfileParams) => {
    const { user } = await updateProfileApi(parmas);
    return user;
  }
);

export const updateProfileReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(updateProfileThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(updateProfileThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    state.openSignupModal = false;
    state.user = { ...state.user, ...payload };
  });
  builder.addCase(updateProfileThunk.rejected, (state, action) => {
    state.isProcessing = false;
    state.error = action.error?.message;
  });
};
