import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { sendEmailConfirmationApi } from 'apis/auth';
import { AuthState } from './type';

export const sendEmailConfirmationThunk = createAsyncThunk(
  'auth/sendEmailConfirmation',
  async (email: string) => {
    const response = await sendEmailConfirmationApi({ email });
    return response;
  }
);

export const sendEmailConfirmationReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(sendEmailConfirmationThunk.pending, (state, action) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(
    sendEmailConfirmationThunk.fulfilled,
    (state, { payload }) => {
      state.isProcessing = false;
    }
  );
  builder.addCase(sendEmailConfirmationThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
