import { createSlice } from '@reduxjs/toolkit';
import { ServiceState } from './type';
import { getServicesReducer } from './getServicesReducer';
import { getPopularServicesReducer } from './getPopularServicesReducer';
import { createServiceReducer } from './createServiceReducer';

export const initialState: ServiceState = {
  isLoading: false,
  isProcessing: false,
  services: undefined,
  popularServices: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getServicesReducer(builder);
    getPopularServicesReducer(builder);
    createServiceReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
