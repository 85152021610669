import { combineReducers } from '@reduxjs/toolkit';
import { reducer as submissionReducer } from '../slices/submission';
import { reducer as authReducer } from '../slices/auth';
import { reducer as provinceReducer } from '../slices/province';
import { reducer as generalReducer } from '../slices/general';
import { reducer as serviceReducer } from '../slices/service';
import { reducer as industryReducer } from '../slices/industry';
import { reducer as softwareReducer } from '../slices/software';
import { reducer as skillReducer } from '../slices/skill';
import { reducer as languageReducer } from '../slices/language';
import { reducer as projectReducer } from '../slices/project';
import { reducer as endUserReducer } from '../slices/endUser';
import { reducer as portfoliosReducer } from '../slices/portfolio';
import { reducer as reportReducer } from '../slices/report';

const rootReducer = combineReducers({
  submission: submissionReducer,
  auth: authReducer,
  general: generalReducer,
  province: provinceReducer,
  service: serviceReducer,
  industry: industryReducer,
  software: softwareReducer,
  skill: skillReducer,
  language: languageReducer,
  project: projectReducer,
  portfolios: portfoliosReducer,
  endUser: endUserReducer,
  report: reportReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
