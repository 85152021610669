import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setAccessToken } from 'apis/base';
import { signupApi, SignupParams } from 'apis/auth';
import { AuthState } from './type';

// https://redux-toolkit.js.org/api/createAsyncThunk
// a failed request or error in a thunk will never return a rejected promise
// If your component needs to know if the request failed, use .unwrap
// by default reject error will be populate in action.error
// Doing return rejectWithValue(errorPayload) will cause the rejected action to use that value as action.payload
export const signupThunk = createAsyncThunk(
  'auth/signup',
  async (params: SignupParams) => {
    const { user, jwt } = await signupApi(params);
    setAccessToken(jwt);
    return user;
  }
);

export const signupReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(signupThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(signupThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    state.openSignupModal = false;
    state.isAuthenticated = true;
    state.user = payload;
  });
  builder.addCase(signupThunk.rejected, (state, action) => {
    state.isProcessing = false;
    state.error = action.error?.message;
  });
};
