import api, { ListResponse } from './base';

export interface StatusTable {
  deliverable: string;
  totalHours: number | undefined;
  status: string;
  progress: number | undefined;
  comment?: string;
}

export interface MonthlyReport {
  id: number;
  attributes: {
    isCompletedOfReportingPeriod: null | boolean;
    projectStatus: null | string;
    progressDescription: null | string;
    statusTable: StatusTable[];
    isReportAmendment: null | boolean;
    amendmentDescription: null | string;
    amount: null | number;
    reportedAt: null | string;
    createdAt: null | string;
    updatedAt: null | string;
    vendorInvoice?: null | {
      data: {
        id: number;
        attributes: {
          name: null | string;
          ext: null | string;
          mime: null | string;
          size: null | number;
          url: null | string;
        };
      };
    };
    amendmentFile?: null | {
      data: {
        id: number;
        attributes: {
          name: null | string;
          ext: null | string;
          mime: null | string;
          size: null | number;
          url: null | string;
        };
      };
    };
    project: null | {
      data: {
        id: number;
        attributes: {
          title: null | string;
          subject: null | string;
          description: null | string;
          startDate: null | string;
          endDate: null | string;
          code: null | string;
          leadFirstname: null | string;
          leadLastname: null | string;
          leadEmail: null | string;
          leadInstitution: null | string;
          budget: null | string;
          progress: null | number;
          descriptionService: null | string;
          descriptionProgress: null | string;
          descriptionPlan: null | string;
          additionalInfo: null | string;
          projectType: null | string;
          isPartVLSProject: null | boolean;
          submissionId: null | string;
          table: {
            data: [
              {
                id: number;
                name: string;
                cvlpHour: number;
                description: string;
                estimatedHour: number;
                instituteHour: number;
              }
            ];
            totalHours: {
              cvlpHour: number;
              estimatedHour: number;
              instituteHour: number;
            };
          };
          isApproved: null | boolean;
          VLSProject: null | string;
          createdAt: null | string;
          updatedAt: null | string;
          publishedAt: null | string;
          communicatePlan: null | string;
          expectedExperience: null | string;
          descriptionResources: null | string;
          signingAuthority: null | string;
          signingAuthorityEmail: null | string;
        };
      };
    };
    vendor: null | {
      data: {
        id: number;
        attributes: {
          username: null | string;
          email: null | string;
          provider: null | string;
          confirmed: null | boolean;
          blocked: null | boolean;
          fullName: null | string;
          contactName: null | string;
          description: null | string;
          companySize: null | string;
          organizeType: null | string;
          preCVLPExp: null | boolean;
          companyName: null | string;
          locations: [
            {
              id: number;
              city: null | string;
              province: null | string;
              isPrimary: null | boolean;
            }
          ];
          linksAndPortfolio: null | string;
          firstName: null | string;
          lastName: null | string;
          primaryProvinceName: null | string;
          primaryCityName: null | string;
          isVendorOfRecord: null | boolean;
          language: null | string;
          createdAt: null | string;
          updatedAt: null | string;
          isApproveForLearnerExperience: null | boolean;
        };
      };
    };
  };
}

export interface MonthlyReportFilterParams {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
}

// Admin gets monthly reports
export const getMonthlyReportsApi = async ({
  startDate,
  endDate,
  page,
  pageSize,
}: MonthlyReportFilterParams) =>
  api.get(
    `/reports?populate=%2A&filters[updatedAt][$gte]=${startDate}&filters[updatedAt][$lt]=${endDate}&sort[0]=updatedAt:desc`
  ) as Promise<ListResponse<MonthlyReport>>;
