import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Divider, Toolbar, Link, Button, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { useSelector, useAppDispatch } from 'store';
import { actions } from 'slices/auth';
import LanguagePopover from './LanguagePopover';
import NavItems from './NavItems';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 34,
      marginRight: 20,
      position: 'relative',
      top: '2px',
      [theme.breakpoints.down(440)]: {
        marginRight: 15,
        marginLeft: -4,
        height: 30,
      },
    },
    logo2: {
      height: 34,
      marginRight: 20,
      [theme.breakpoints.down(440)]: {
        height: 30,
      },
    },
  })
);

const MainNavbar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const user = useSelector((state) => state.auth.user);
  const openSignupModal = () => {
    dispatch(actions.toggleSignupModal(true));
  };
  const openLoginModal = () => {
    dispatch(actions.toggleLoginModal(true));
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.primary',
      }}
      role="header"
    >
      <Box paddingX={3}>
        <Toolbar disableGutters sx={{ height: 80 }}>
          <Box display="flex">
            <Link component={RouterLink} to="/" sx={{ lineHeight: 1 }}>
              <img
                alt="ecampus"
                src="/static/ecampus-logo-purple.svg"
                className={classes.logo}
              />
            </Link>
            <img
              alt="ecampus"
              src="/static/central-virtual2.svg"
              className={classes.logo2}
            />
          </Box>
          <NavItems />
          <LanguagePopover />
          {!user && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  '@media screen and (max-width: 767px)': {
                    backgroundColor: 'white',
                    padding: '16px 0',
                    position: 'absolute',
                    left: '-24px',
                    top: '80px',
                    width: 'calc(100% + 48px)',
                    justifyContent: 'center',
                    borderTop: '1px solid #E9E8EB',
                  },
                }}
              >
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    marginX: 1,
                  }}
                  onClick={openSignupModal}
                >
                  {t('navigation.joinBtn')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={openLoginModal}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {t('navigation.loginBtn')}
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default MainNavbar;
