import api, { ListResponse, MutationResponse, PaginationParam } from './base';
import { Industry } from './industry';
import { Service } from './service';
import { EndUser } from './endUser';
import { User } from './auth';

export type ProjectType = 'fastTrack' | 'funded';
export interface ProjectTypeStatus {
  isEnableFunded: boolean;
  isEnableFastTrack: boolean;
  isEnableLearnerExperience: boolean;
}
export interface ChosenService {
  id: number;
  name: string;
  description: string;
  estimatedHour: number;
  instituteHour: number;
  cvlpHour: number;
}
export interface TotalHours {
  estimatedHour: number;
  instituteHour: number;
  cvlpHour: number;
}
export type DataTable = {
  data: ChosenService[];
  totalHours: TotalHours;
};

export interface ProjectParams {
  projectType: ProjectType;
  leadFirstname: string;
  leadLastname: string;
  signingAuthority: string;
  signingAuthorityEmail: string;
  leadEmail: string;
  leadInstitution: string;
  title: string;
  description: string;
  industry: number;
  subject: string;
  startDate: string;
  endDate: string;
  end_users: number[];
  budget?: string;
  isPartVLSProject?: boolean;
  VLSProject?: string;
  services: number[];
  table: DataTable;
  descriptionProgress: string;
  descriptionService: string;
  communicatePlan: string;
  expectedExperience: string;
  descriptionPlan: string;
  progress: number;
  descriptionResources: string;
  additionalInfo: string;
  code?: string;
  vendor?: {
    data: { id: number; attributes: User };
  };
  matcheds?: {
    data: MatchedProjectsInfo[];
  };
  isApproved?: boolean;
  canSubmitInvoice?: boolean;
  canSubmitReport?: boolean;
}

export interface ProjectDetailsAttributes
  extends Omit<ProjectParams, 'industry' | 'services' | 'end_users'> {
  industry: { data: Industry };
  services: { data: Service[] };
  end_users: { data: EndUser[] };
  owner?: { data: { id: number; attributes: User } };
  report?: {
    id: number;
    isCompletedOfReportingPeriod: null | boolean;
    projectStatus: null | string;
    progressDescription: null | string;
    statusTable: any;
    isReportAmendment: null | boolean;
    amendmentDescription: null | string;
    amount: null | number;
    amendmentFile: null | {
      id: number;
      name: string;
      url: string;
    };
    vendorInvoice?: null | {
      id: number;
      name: string;
      url: string;
    };
  };
  canSubmitInvoice?: boolean;
  canSubmitReport?: boolean;
}

export interface Project {
  id: number;
  attributes: ProjectParams;
}

export interface ProjectDetails {
  id: number;
  attributes: ProjectDetailsAttributes;
}
export interface MatchedProjectsInfo {
  id: number;
  attributes: {
    status:
      | 'waitingForVendorResponse'
      | 'connectionAccepted'
      | 'connectionSelectedByPSI';
    rejectedDesription: string | null;
    institutionName: string;
    project: { data: ProjectDetails };
    user?: { data: { id: number; attributes: User } };
  };
}

export type ProjectMatchedStatus =
  | 'waitingForVendorResponse'
  | 'connectionAccepted'
  | 'connectionSelectedByPSI'
  | 'connectionRejectedByVendor'
  | 'connectionRejectedByPSI';

export type ProjectReportStatus = 'onTrack' | 'delayed' | 'completed';
export interface StatusTable {
  deliverable: string;
  totalHours: number | undefined;
  status: string;
  progress: number | undefined;
  comment?: string;
}

export interface UpdateProjectMatchedStatusAttributes {
  project: number;
  user: number;
  status: ProjectMatchedStatus;
  rejectedDescription?: string | null;
}

export interface UpdateProjectMatchedStatus {
  id: number;
  attributes: UpdateProjectMatchedStatusAttributes;
}

export interface MonthlyReportParams {
  isCompletedOfReportingPeriod: boolean;
  projectStatus?: string;
  progressDescription?: string;
  statusTable?: any;
  isReportAmendment?: boolean;
  amendmentDescription?: string;
  amendmentFile?: null | number; // or Id of uploaded file
  amount?: number;
}

export const createProjectApi = async (params: ProjectParams) =>
  api.post('/projects', { data: params }) as Promise<MutationResponse<Project>>;

export const checkProjectTypeStatus = async () =>
  api.post('/projects/check-project-type') as Promise<
    MutationResponse<ProjectTypeStatus>
  >;

// Institution get own projects
export const getProjectsApi = async ({ page, pageSize }: PaginationParam) =>
  api.get(
    `/projects/get-user-projects?pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc`
  ) as Promise<ListResponse<Project>>;

// Vendor get matched projects
export const getMatchedProjectsApi = async ({
  page,
  pageSize,
}: PaginationParam) =>
  api.get(
    `/matcheds/get-connections?pagination[page]=${page}&pagination[pageSize]=${pageSize}`
  ) as Promise<ListResponse<MatchedProjectsInfo>>;

export const getProjectDetailApi = async (id: number) =>
  api.get(`/projects/${id}`) as Promise<MutationResponse<ProjectDetails>>;

export const getProjectDetailWithStatusApi = async (id: number) =>
  api.get(`matcheds/${id}?populate=project`) as Promise<
    MutationResponse<MatchedProjectsInfo>
  >;

export const updateProjectMatchedStatusApi = async (
  params: UpdateProjectMatchedStatusAttributes
) =>
  api.post(`matcheds/update-status`, { data: params }) as Promise<
    MutationResponse<UpdateProjectMatchedStatus>
  >;

export const submitInvoiceApi = async (params: {
  project: number;
  vendorInvoice: number;
}) =>
  api.post('/reports', { data: params }) as Promise<
    MutationResponse<UpdateProjectMatchedStatus>
  >;

export const submitMonthlyReportApi = async (
  projectId: number,
  params: MonthlyReportParams
) =>
  api.put(`/reports/${projectId}`, { data: params }) as Promise<
    MutationResponse<UpdateProjectMatchedStatus>
  >;
