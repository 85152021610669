import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { selectMainVendorApi, SelectVendorParams } from 'apis/matchedVendor';
import { ProjectState } from './type';

export const selectMainVendorThunk = createAsyncThunk(
  'project/selectMainVendor',
  async (params: SelectVendorParams) => {
    const response = await selectMainVendorApi(params);
    return response;
  }
);

export const selectMainVendorReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(selectMainVendorThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(selectMainVendorThunk.fulfilled, (state) => {
    state.isProcessing = false;
  });
  builder.addCase(selectMainVendorThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
