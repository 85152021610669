import React, { useState, useEffect } from 'react';
import { Dialog, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useSelector } from 'store';
import Button from 'components/Button';
import { SignupParams } from 'apis/auth';
import { actions } from 'slices/auth';
import { signupThunk } from 'slices/auth/signupReducer';
import { getCommonThunk } from 'slices/general/getCommonReducer';
import { SIGNUP_STEPS, USER_ROLES } from '../../../constants';
import { routesConstant } from '../../../constants/routeConstant';
import RoleForm from './RoleForm';
import UserForm from './UserForm';
import PasswordForm from './PasswordForm';

const SignupModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { openSignupModal } = useSelector((state) => state.auth);
  const [signupStep, setSignupStep] = useState(SIGNUP_STEPS.RoleForm);
  const [formData, setFormData] = useState<SignupParams>();

  const { commonDefination } = useSelector((state) => state.general);

  const FORM_TITLES = {
    select: t('formTitlesSelect'),
    [USER_ROLES.Vendor]: t('formTitlesVendor'),
    [USER_ROLES.Institution]: t('formTitlesInstitution'),
    [USER_ROLES.CvlpAdmin]: t('formTitlesCvlpAdmin'),
  };

  const REDIRECT_URLS = {
    [USER_ROLES.Vendor]: routesConstant.vendor.onboarding.general,
    [USER_ROLES.Institution]: routesConstant.dashboard,
  };

  useEffect(() => {
    if (!commonDefination) dispatch(getCommonThunk());
  }, [commonDefination]);

  const resetStep = () => {
    setSignupStep(SIGNUP_STEPS.RoleForm);
  };

  const toggleSignupModal = (open: boolean) => () => {
    dispatch(actions.toggleSignupModal(open));
    resetStep();
  };

  const toggleLoginModal = (open: boolean) => () => {
    dispatch(actions.toggleLoginModal(open));
    resetStep();
  };

  const handleUserFormChange = (formData: SignupParams, nextStep: string) => {
    setFormData((pre) => ({ ...pre, ...formData }));
    setSignupStep(nextStep);
  };

  const handleSubmit = (password: string) => {
    const params = formData as SignupParams;
    // application lang
    params.language = i18n.resolvedLanguage || 'en';

    if (params.role === USER_ROLES.Vendor) {
      const defaultLangIsoCode = i18n.language || 'en';
      const defaultLang = commonDefination?.languages.find(
        (lang) => lang?.code === defaultLangIsoCode
      );
      if (defaultLang) {
        params.languages = [
          {
            id: defaultLang.id,
            name: defaultLang.name,
            experienceLevel:
              commonDefination?.languageLevelList[0]?.value || 'basic',
          },
        ];
      }
    }

    dispatch(
      signupThunk({
        ...params,
        password,
        username: params.email,
      })
    ).then((action) => {
      if (action?.meta?.requestStatus === 'fulfilled') {
        resetStep();
        navigate(REDIRECT_URLS[params.role] || routesConstant.home);
        toast.success(t('signupSuccessfully'));
      }
    });
  };

  return (
    <Dialog
      open={openSignupModal}
      onClose={toggleSignupModal(false)}
      maxWidth="md"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: 540,
          '@media screen and (max-width: 599px)': {
            width: '100%',
          },
          minHeight: 560,
          pt: (theme) => theme.spacing(4),
          px: (theme) => theme.spacing(5),
          pb: (theme) => theme.spacing(4.5),
        }}
      >
        <Box sx={{ flex: 1, mb: 2 }}>
          <Typography
            variant="h5"
            fontWeight="fontWeightBold"
            color="common.black"
            sx={{ mb: 2, textAlign: 'center' }}
          >
            {signupStep !== SIGNUP_STEPS.RoleForm && formData
              ? FORM_TITLES[formData.role]
              : FORM_TITLES.select}
          </Typography>
          {signupStep === SIGNUP_STEPS.RoleForm && (
            <RoleForm onChange={handleUserFormChange} />
          )}
          {signupStep === SIGNUP_STEPS.UserForm && (
            <UserForm
              onChange={handleUserFormChange}
              role={formData?.role || USER_ROLES.Vendor}
            />
          )}
          {signupStep === SIGNUP_STEPS.PasswordForm && (
            <PasswordForm handleSubmit={handleSubmit} />
          )}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1" fontWeight="500">
            {t('alreadyRegistered')}
          </Typography>
          <Button
            variant="text"
            color="secondary"
            sx={{
              padding: 0,
              textTransform: 'none',
            }}
            onClick={toggleLoginModal(true)}
          >
            {t('login')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SignupModal;
