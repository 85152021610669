import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createProvinceApi, CreateProvinceParams } from 'apis/province';
import { ProvinceState } from './type';

export const createProvinceThunk = createAsyncThunk(
  'province/createProvince',
  async (params: CreateProvinceParams, { dispatch }) => {
    const { data } = await createProvinceApi(params);
    return data;
  }
);

export const createProvinceReducer = (
  builder: ActionReducerMapBuilder<ProvinceState>
): void => {
  builder.addCase(createProvinceThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(createProvinceThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(createProvinceThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
