import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getPopularProvincesApi } from 'apis/province';
import { ProvinceState } from './type';

export const getPopularProvincesThunk = createAsyncThunk(
  'province/getPopularProvinces',
  async () => {
    const { data } = await getPopularProvincesApi();
    return data.filter((item) => item.attributes.popular);
  }
);

export const getPopularProvincesReducer = (
  builder: ActionReducerMapBuilder<ProvinceState>
): void => {
  builder.addCase(getPopularProvincesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getPopularProvincesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.popularProvinces = payload;
  });
  builder.addCase(getPopularProvincesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
