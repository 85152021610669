import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { submitInvoiceApi } from 'apis/project';
import { ProjectState } from './type';

export const submitInvoiceThunk = createAsyncThunk(
  'project/submitInvoice',
  async (params: { project: number; vendorInvoice: number }) => {
    const response = await submitInvoiceApi(params);
    return response;
  }
);

export const submitInvoiceReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(submitInvoiceThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(submitInvoiceThunk.fulfilled, (state) => {
    state.isProcessing = false;
    if (state.projectDetail) {
      state.projectDetail.attributes = {
        ...state.projectDetail.attributes,
        canSubmitInvoice: false,
      };
    }
  });
  builder.addCase(submitInvoiceThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
