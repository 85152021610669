import { createSlice } from '@reduxjs/toolkit';
import { SoftwareState } from './type';
import { getAllSoftwaresReducer } from './getAllSoftwaresReducer';
export const initialState: SoftwareState = {
  isLoading: false,
  softwares: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'software',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAllSoftwaresReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
