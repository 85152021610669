import api, { ListResponse, MutationResponse } from './base';

export interface Language {
  id: number;
  attributes: { name: string; popular: boolean; code: string };
}

export interface CreateLanguageParams {
  name: string;
  popular: boolean;
}

export const getLanguagesApi = async () =>
  api.get('/languages') as Promise<ListResponse<Language>>;

export const getPopularLanguagesApi = async () =>
  api.post('/languages/all?filters[popular][$eq]=true') as Promise<
    ListResponse<Language>
  >;

export const createLanguageApi = async (params: CreateLanguageParams) =>
  api.post('/languages', { data: params }) as Promise<
    MutationResponse<Language>
  >;
