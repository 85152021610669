import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllServicesApi } from 'apis/service';
import { ServiceState } from './type';

export const getServicesThunk = createAsyncThunk(
  'service/getServices',
  async () => {
    const services = await getAllServicesApi();
    return services;
  }
);

export const getServicesReducer = (
  builder: ActionReducerMapBuilder<ServiceState>
): void => {
  builder.addCase(getServicesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getServicesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.services = payload.data;
  });
  builder.addCase(getServicesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
