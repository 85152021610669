import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationParam } from 'apis/base';
import { getProjectsApi } from 'apis/project';
import { ProjectState } from './type';

export const getInstitutionProjectsThunk = createAsyncThunk(
  'project/getInstitutionProjects',
  async (param: PaginationParam) => {
    const response = await getProjectsApi(param);
    return response;
  }
);

export const getInstitutionProjectsReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(getInstitutionProjectsThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(
    getInstitutionProjectsThunk.fulfilled,
    (state, { payload }) => {
      state.isLoading = false;
      state.institutionProjects = payload;
    }
  );
  builder.addCase(getInstitutionProjectsThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
