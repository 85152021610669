import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getBannersApi } from 'apis/general';
import { GeneralState } from './type';

export const getBannersThunk = createAsyncThunk(
  'common/getBanners',
  async () => await getBannersApi()
);

export const getBannersReducer = (
  builder: ActionReducerMapBuilder<GeneralState>
): void => {
  builder.addCase(getBannersThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getBannersThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.banners = payload.data;
  });
  builder.addCase(getBannersThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
