import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { checkEmailExistedApi } from 'apis/auth';
import { AuthState } from './type';

export const checkEmailExistedThunk = createAsyncThunk(
  'auth/checkEmailExisted',
  async (email: string) => {
    const response = await checkEmailExistedApi({ email });
    return response;
  }
);

export const checkEmailExistedReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(checkEmailExistedThunk.pending, (state, action) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(checkEmailExistedThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(checkEmailExistedThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
