import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setRequestAuthorizationHeader } from 'apis/base';
import { meApi } from 'apis/auth';
import { ECA_ACCESS_TOKEN } from '../../constants';
import { AuthState } from './type';

// https://redux-toolkit.js.org/api/createAsyncThunk
// a failed request or error in a thunk will never return a rejected promise
// If your component needs to know if the request failed, use .unwrap
// by default reject error will be populate in action.error
// Doing return rejectWithValue(errorPayload) will cause the rejected action to use that value as action.payload
export const initializeThunk = createAsyncThunk('auth/initialize', async () => {
  try {
    let user;
    const accessToken = window.localStorage.getItem(ECA_ACCESS_TOKEN);

    if (accessToken) {
      setRequestAuthorizationHeader(accessToken);
      user = await meApi();
    }
    return {
      isAuthenticated: Boolean(accessToken),
      user,
    };
  } catch (err) {
    return {
      isAuthenticated: false,
      user: undefined,
      error: (err as Error)?.message,
    };
  }
});

export const initializeReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(initializeThunk.fulfilled, (state, { payload }) => {
    const { isAuthenticated, user } = payload;
    state.isInitialized = true;
    state.isAuthenticated = isAuthenticated;
    state.user = user;
    state.error = payload.error;
  });
};
