import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { resetPasswordApi, ResetPasswordParams } from 'apis/auth';
import { AuthState } from './type';

export const resetPasswordThunk = createAsyncThunk(
  'auth/resetPassword',
  async (params: ResetPasswordParams) => {
    const response = await resetPasswordApi(params);
    return response;
  }
);

export const resetPasswordReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(resetPasswordThunk.pending, (state, action) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(resetPasswordThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(resetPasswordThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
