import React from 'react';
import { Box, Typography, Button as MuiButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { SignupParams } from 'apis/auth';
import { USER_ROLES, SIGNUP_STEPS } from '../../../constants';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  fontSize: '15px',
  fontWeight: 'bold',
  borderRadius: '100px',
  padding: '10px 30px',
  textTransform: 'none',
}));

interface Props {
  onChange: (formData: SignupParams, nextStep: string) => void;
}

const RoleForm: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('common');
  return (
    <Box mt={5}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t('joinOurPlatform')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          '@media screen and (max-width: 599px)': {
            flexDirection: 'column',
            gap: '16px',
          },
        }}
      >
        <Button
          variant="outlined"
          sx={{ mr: { xs: '0', sm: '16px' } }}
          onClick={() =>
            onChange(
              { role: USER_ROLES.Vendor } as SignupParams,
              SIGNUP_STEPS.UserForm
            )
          }
        >
          {t('serviceProvider')}
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            onChange(
              { role: USER_ROLES.Institution } as SignupParams,
              SIGNUP_STEPS.UserForm
            )
          }
        >
          {t('memberInstitution')}
        </Button>
      </Box>
    </Box>
  );
};

export default RoleForm;
