import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getPopularIndustriesApi } from 'apis/industry';
import { IndustryState } from './type';

export const getPopularIndustriesThunk = createAsyncThunk(
  'industry/getPopularIndustries',
  async () => {
    const { data } = await getPopularIndustriesApi();
    return data.filter((item) => item.attributes.popular);
  }
);

export const getPopularIndustriesReducer = (
  builder: ActionReducerMapBuilder<IndustryState>
): void => {
  builder.addCase(getPopularIndustriesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getPopularIndustriesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.popularIndustries = payload;
  });
  builder.addCase(getPopularIndustriesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
