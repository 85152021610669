import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import Button from 'components/Button';
import { grey4 } from 'theme/color';
import { PASSWORD_REGREX } from '../../../constants';

interface Props {
  handleSubmit: (password: string) => void;
  okText?: string;
}

const PasswordForm: React.FC<Props> = ({ handleSubmit, okText = 'join' }) => {
  const { t } = useTranslation();
  const { error, isProcessing } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((pre) => !pre);
  const toggleShowConfirmPassword = () => setShowConfirmPassword((pre) => !pre);

  okText = t(okText);

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .matches(PASSWORD_REGREX, t('passwordInvalid'))
          .required(t('missingField', { fieldName: 'Password' })),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], t('confirmPasswordInvalid'))
          .required(t('missingField', { fieldName: 'Confirm password' })),
      })}
      onSubmit={async ({ password }) => {
        handleSubmit(password);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            autoFocus
            fullWidth
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            label={t('enterPassword')}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            label={t('reEnterPassword')}
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showConfirmPassword ? 'text' : 'password'}
            value={values.confirmPassword}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={toggleShowConfirmPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography sx={{ mt: 4, color: grey4 }}>
            {t('passwordInstruction')}
          </Typography>
          {error && (
            <Box sx={{ mt: 3 }}>
              <Alert severity="error">{t(error)}</Alert>
            </Box>
          )}
          <Button
            sx={{ mt: 2.5, textTransform: 'none' }}
            loading={isProcessing}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
          >
            {okText}
          </Button>
          <Typography
            sx={{
              mt: 3,
              mb: 1,
              marginX: 5,
              color: grey4,
              textAlign: 'center',
            }}
            variant="body2"
          >
            {t('agreeToTerms')}
          </Typography>
        </form>
      )}
    </Formik>
  );
};

export default PasswordForm;
