import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getCommonDefinationApi } from 'apis/general';
import { GeneralState } from './type';

export const getCommonThunk = createAsyncThunk('common/getCommon', async () => {
  const data = await getCommonDefinationApi();
  return data;
});

export const getCommonReducer = (
  builder: ActionReducerMapBuilder<GeneralState>
): void => {
  builder.addCase(getCommonThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getCommonThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.commonDefination = payload.data;
  });
  builder.addCase(getCommonThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
