export const routesConstant = {
  home: '/',
  about: '',
  emailConfirm: '/email-confirmation',
  signup: {
    success: '/signup-success',
  },
  vendor: {
    onboarding: {
      general: '/vendor-onboarding',
      services: '/vendor-onboarding/services',
      industries: '/vendor-onboarding/industries',
      skills: '/vendor-onboarding/skills',
    },
    profile: (id: string | number = ':id'): string => `/vendors/${id}`,
  },
  projects: {
    home: '/projects',
    detail: (id: string | number = ':id'): string => `/projects/${id}`,
    monthlyReport: (id: string | number = ':id'): string =>
      `/projects/${id}/monthly-report`,
    new: {
      lead: '/projects/new/lead',
      details: '/projects/new/details',
      match: '/projects/new/match',
      confirmation: '/projects/new/confirmation',
    },
    matchedVendor: (projectId: number | string, vendorId: number | string) =>
      `/projects/${projectId}/matched-vendors/${vendorId}`,
    matchedVendors: (id: string | number = ':id'): string =>
      `/projects/${id}/matched-vendors`,
    matchedProject: (
      projectId: number | string = ':id',
      connectionId: number | string = ':id'
    ) => `/projects/${projectId}/matched-project/${connectionId}`,
  },
  dashboard: '/dashboard',
  profile: '/profile',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
};
