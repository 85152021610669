import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getIndustriesApi } from 'apis/industry';
import { IndustryState } from './type';

export const getIndustriesThunk = createAsyncThunk(
  'industry/getIndustries',
  async () => {
    const industries = await getIndustriesApi();
    return industries;
  }
);

export const getIndustriesReducer = (
  builder: ActionReducerMapBuilder<IndustryState>
): void => {
  builder.addCase(getIndustriesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getIndustriesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.industries = payload.data;
  });
  builder.addCase(getIndustriesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
