import { createSlice } from '@reduxjs/toolkit';
import { IndustryState } from './type';
import { getIndustriesReducer } from './getIndustriesReducer';
import { getPopularIndustriesReducer } from './getPopularIndustriesReducer';
import { createIndustryReducer } from './createIndustryReducer';

export const initialState: IndustryState = {
  isLoading: false,
  isProcessing: false,
  industries: undefined,
  popularIndustries: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'industry',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getIndustriesReducer(builder);
    getPopularIndustriesReducer(builder);
    createIndustryReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
