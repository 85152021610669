import api, { ListResponse, MutationResponse } from './base';

export interface Industry {
  id: number;
  attributes: {
    name: string;
    popular: boolean;
    subjectList?: {
      value: string;
      label: string;
      options: { value: string; label: string }[];
    }[];
  };
}

export interface CreateIndustryParams {
  name: string;
}

export const getIndustriesApi = async () =>
  api.get('/industries') as Promise<ListResponse<Industry>>;

export const getPopularIndustriesApi = async () =>
  api.post('/industries/all?filters[popular][$eq]=true') as Promise<
    ListResponse<Industry>
  >;

export const createIndustryApi = async (params: CreateIndustryParams) =>
  api.post('/industries', { data: params }) as Promise<
    MutationResponse<Industry>
  >;
