import api, { ListResponse, MutationResponse } from './base';

export interface EndUser {
  id: number;
  attributes: { name: string; popular: boolean };
}

export interface CreateEndUserParams {
  name: string;
}

export const getEndUsersApi = async () =>
  api.get('/end-users') as Promise<ListResponse<EndUser>>;

export const getPopularEndUsersApi = async () =>
  api.post('/end-users/all?filters[popular][$eq]=true') as Promise<
    ListResponse<EndUser>
  >;

export const createEndUserApi = async (params: CreateEndUserParams) =>
  api.post('/end-users', { data: params }) as Promise<
    MutationResponse<EndUser>
  >;
