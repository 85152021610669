import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllSoftwaresApi } from 'apis/software';
import { SoftwareState } from './type';

export const getAllSoftwaresThunk = createAsyncThunk(
  'software/getAllSoftwares',
  async () => {
    const { data } = await getAllSoftwaresApi();
    return data;
  }
);

export const getAllSoftwaresReducer = (
  builder: ActionReducerMapBuilder<SoftwareState>
): void => {
  builder.addCase(getAllSoftwaresThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getAllSoftwaresThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.softwares = payload;
  });
  builder.addCase(getAllSoftwaresThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
