import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createLanguageApi, CreateLanguageParams } from 'apis/language';
import { LanguageState } from './type';

export const createLanguageThunk = createAsyncThunk(
  'language/createLanguage',
  async (params: CreateLanguageParams) => {
    const { data } = await createLanguageApi(params);
    return data;
  }
);

export const createLanguageReducer = (
  builder: ActionReducerMapBuilder<LanguageState>
): void => {
  builder.addCase(createLanguageThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(createLanguageThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(createLanguageThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
