import React from 'react';
import { Dialog, Box, Typography, Alert, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { grey4 } from 'theme/color';
import { useAppDispatch, useSelector } from 'store';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { actions } from 'slices/auth';
import { forgotPasswordThunk } from 'slices/auth/forgotPasswordReducer';

const ForgotPasswordModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openForgotPasswordModal, error, isProcessing } = useSelector(
    (state) => state.auth
  );

  const toggleForgotPasswordModal = (open: boolean) => () => {
    dispatch(actions.toggleForgotPasswordModal(open));
  };

  const toggleLoginModal = (open: boolean) => () => {
    dispatch(actions.toggleLoginModal(open));
  };

  return (
    <Dialog
      open={openForgotPasswordModal}
      onClose={toggleForgotPasswordModal(false)}
      maxWidth="md"
    >
      <Box
        sx={{
          width: 540,
          '@media screen and (max-width: 767px)': {
            width: '100%',
          },
          minHeight: 500,
          display: 'flex',
          flexDirection: 'column',
          pt: (theme) => theme.spacing(4),
          px: (theme) => theme.spacing(5),
          pb: (theme) => theme.spacing(4.5),
        }}
      >
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              variant="h5"
              fontWeight="fontWeightBold"
              color="common.black"
              sx={{ mb: 2, textAlign: 'center' }}
            >
              {t('passwordReset')}
            </Typography>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .trim()
                  .email(t('invalidField', { fieldName: 'Email' }))
                  .required(t('missingField', { fieldName: 'Email' })),
              })}
              onSubmit={async ({ email }) => {
                dispatch(forgotPasswordThunk(email)).then((action) => {
                  if (action?.meta?.requestStatus === 'fulfilled') {
                    toast.success(t('passwordResetSuccess'));
                    toggleForgotPasswordModal(false)();
                  }
                });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t('passwordResetEmailLabel')}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  {error && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="error">{t(error)}</Alert>
                    </Box>
                  )}
                  <Button
                    sx={{ mt: 2, textTransform: 'none' }}
                    loading={isProcessing}
                    fullWidth
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    {t('passwordResetSendLink')}
                  </Button>
                  <Typography
                    sx={{
                      mt: 2.5,
                      color: grey4,
                      textAlign: 'center',
                    }}
                    variant="body2"
                  >
                    {t('passwordResetSendLinkCopy')}
                  </Typography>
                </form>
              )}
            </Formik>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={4}
          >
            <Typography variant="body1" fontWeight="500">
              {t('iRemember')}
            </Typography>
            <Button
              variant="text"
              color="secondary"
              sx={{
                padding: 0,
                textTransform: 'none',
              }}
              onClick={toggleLoginModal(true)}
            >
              {t('login')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ForgotPasswordModal;
