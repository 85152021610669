import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createIndustryApi, CreateIndustryParams } from 'apis/industry';
import { IndustryState } from './type';

export const createIndustryThunk = createAsyncThunk(
  'industry/createIndustry',
  async (params: CreateIndustryParams) => {
    const { data } = await createIndustryApi(params);
    return data;
  }
);

export const createIndustryReducer = (
  builder: ActionReducerMapBuilder<IndustryState>
): void => {
  builder.addCase(createIndustryThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(createIndustryThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(createIndustryThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
