import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useSelector } from 'store';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import AuthenticatedNavbar from './AuthenticatedNavbar';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  paddingTop: 80,
}));

type Props = {
  children?: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <MainLayoutRoot role="main">
      {!user && <MainNavbar />}
      {user && <AuthenticatedNavbar />}
      {children || <Outlet />}
      <Footer />
    </MainLayoutRoot>
  );
};

export default MainLayout;
