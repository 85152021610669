import { createSlice } from '@reduxjs/toolkit';
import { ProvinceState } from './type';
import { getProvincesReducer } from './getProvincesReducer';
import { createProvinceReducer } from './createProvinceReducer';
import { getPopularProvincesReducer } from 'slices/province/getPopularProvincesReducer';
export const initialState: ProvinceState = {
  isLoading: false,
  provinces: undefined,
  error: undefined,
  isProcessing: false,
};

const slice = createSlice({
  name: 'province',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getProvincesReducer(builder);
    getPopularProvincesReducer(builder);
    createProvinceReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
