import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  TextField,
  FormHelperText,
  ListSubheader,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SignupParams } from 'apis/auth';
import { actions } from 'slices/auth';
import { useAppDispatch, useSelector } from 'store';
import { checkEmailExistedThunk } from 'slices/auth/checkEmailExistedReducer';
import { SIGNUP_STEPS, USER_ROLES } from '../../../constants';
interface Props {
  role: 'authenticated' | 'institution' | 'cvlpadmin';
  onChange: (formData: SignupParams, nextStep: string) => void;
}

const UserForm: React.FC<Props> = ({ onChange, role }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [emailError, setEmailError] = useState('');
  const [isContinue, setIsContinue] = useState(false);

  const commonDefination = useSelector(
    (state) => state.general.commonDefination
  );

  useEffect(() => {
    setIsContinue(false);
  }, [emailError]);

  const toggleLoginModal = (open: boolean) => () => {
    dispatch(actions.toggleLoginModal(open));
  };

  const leadInstitionNameOptions = useMemo(() => {
    const institutionNames = commonDefination?.institutionNames || [];
    const items: any[] = [];
    institutionNames.forEach((group) => {
      items.push(
        <ListSubheader
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
          }}
          key={group.value}
        >
          {group.label}
        </ListSubheader>
      );
      group.options.forEach((option) => {
        items.push(
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        );
      });
    });
    return items;
  }, [commonDefination]);

  return (
    <Formik
      initialValues={{
        fullName: '',
        firstName: '',
        lastName: '',
        contactName: '',
        email: '',
      }}
      validationSchema={Yup.object().shape({
        fullName: Yup.string()
          .trim()
          .required(t('missingField', { fieldName: 'Name' })),
        firstName:
          role === USER_ROLES.Institution
            ? Yup.string()
                .trim()
                .required(t('missingField', { fieldName: 'First name' }))
            : Yup.string(),
        lastName:
          role === USER_ROLES.Institution
            ? Yup.string()
                .trim()
                .required(t('missingField', { fieldName: 'Last name' }))
            : Yup.string(),
        contactName:
          role === USER_ROLES.Vendor
            ? Yup.string()
                .trim()
                .required(t('missingField', { fieldName: 'Contact name' }))
            : Yup.string(),
        email: Yup.string()
          .trim()
          .email(t('invalidField', { fieldName: 'Email' }))
          .required(t('missingField', { fieldName: 'Email' })),
      })}
      onSubmit={async (values) => {
        dispatch(checkEmailExistedThunk(values.email)).then((action) => {
          if (action?.meta?.requestStatus === 'fulfilled') {
            setEmailError('');
            isContinue &&
              onChange(values as SignupParams, SIGNUP_STEPS.PasswordForm);
          } else {
            setEmailError(t('emailError.message'));
          }
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {role === USER_ROLES.Vendor && (
            <TextField
              autoFocus
              error={Boolean(touched.fullName && errors.fullName)}
              fullWidth
              helperText={touched.fullName && errors.fullName}
              label={t('serviceProviderName')}
              margin="normal"
              name="fullName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.fullName}
              variant="outlined"
            />
          )}
          {role === USER_ROLES.Institution && (
            <TextField
              select
              autoFocus
              error={Boolean(touched.fullName && errors.fullName)}
              fullWidth
              helperText={touched.fullName && errors.fullName}
              label={t('institutionName')}
              margin="normal"
              name="fullName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.fullName}
              variant="outlined"
            >
              {leadInstitionNameOptions}
            </TextField>
          )}
          {role === USER_ROLES.Vendor && (
            <TextField
              error={Boolean(touched.contactName && errors.contactName)}
              fullWidth
              helperText={touched.contactName && errors.contactName}
              label={t('contactName')}
              margin="normal"
              name="contactName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.contactName}
              variant="outlined"
            />
          )}
          {role === USER_ROLES.Institution && (
            <>
              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                label={t('firstName')}
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                label={t('lastName')}
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="outlined"
              />
            </>
          )}
          <TextField
            error={Boolean((touched.email && errors.email) || emailError)}
            fullWidth
            helperText={
              (touched.email && errors.email) ||
              (emailError && (
                <FormHelperText sx={{ marginX: 0 }} component="span">
                  {emailError}{' '}
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{
                      padding: 0,
                      fontSize: '12px',
                      textTransform: 'none',
                      minWidth: 'fit-content',
                    }}
                    onClick={toggleLoginModal(true)}
                  >
                    {t('button.login')}
                  </Button>
                </FormHelperText>
              ))
            }
            label={t('passwordResetEmailLabel')}
            margin="normal"
            name="email"
            onBlur={() => handleSubmit()}
            onChange={(e) => {
              handleChange(e);
              setEmailError('');
            }}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <Button
            sx={{ mt: 5, textTransform: 'none' }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            onClick={() => setIsContinue(true)}
          >
            {t('button.continue')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default UserForm;
