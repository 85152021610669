import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch, useSelector } from 'store';
import { actions } from 'slices/auth';
import { routesConstant } from '../../constants/routeConstant';

interface Props {
  children?: React.ReactNode;
}

const AuthGuard: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    dispatch(actions.toggleLoginModal(true));
    return <Navigate to={routesConstant.home} />;
  }

  return <>{children || <Outlet />}</>;
};

export default AuthGuard;
