import api, { ListResponse, MutationResponse } from './base';

export interface Province {
  id: number;
  attributes: { name: string; popular: boolean };
}

export interface CreateProvinceParams {
  name: string;
  popular: boolean;
}

export const getProvincesApi = async () =>
  api.get('/locations') as Promise<ListResponse<Province>>;

export const getPopularProvincesApi = async () =>
  api.post('/locations/all?filters[popular][$eq]=true') as Promise<
    ListResponse<Province>
  >;

export const createProvinceApi = async (params: CreateProvinceParams) =>
  api.post('/locations', { data: params }) as Promise<
    MutationResponse<Province>
  >;
