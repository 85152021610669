import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getPortfolioApi } from 'apis/portfolio';
import { PortfolioState } from './type';

export const getPortfolioThunk = createAsyncThunk(
  'portfolio/getPortfolio',
  async (id: number) => {
    const response = await getPortfolioApi(id);
    return response.data;
  }
);

export const getPortfolioReducer = (
  builder: ActionReducerMapBuilder<PortfolioState>
): void => {
  builder.addCase(getPortfolioThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getPortfolioThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    const udpatedPortfolioIndex = state.portfolios.data.findIndex(
      (item) => item.id === payload.id
    );
    if (udpatedPortfolioIndex >= 0) {
      state.portfolios.data.splice(udpatedPortfolioIndex, 1, payload);
    }
  });
  builder.addCase(getPortfolioThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
