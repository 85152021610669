import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createServiceApi, CreateServiceParam } from 'apis/service';
import { ServiceState } from './type';

export const createServiceThunk = createAsyncThunk(
  'service/createService',
  async (params: CreateServiceParam) => {
    const { data } = await createServiceApi(params);
    return data;
  }
);

export const createServiceReducer = (
  builder: ActionReducerMapBuilder<ServiceState>
): void => {
  builder.addCase(createServiceThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(createServiceThunk.fulfilled, (state) => {
    state.isProcessing = false;
  });
  builder.addCase(createServiceThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
