import React, { useMemo } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import GlobeIcon from '../icons/Globe';
import ArrowDownIcon from '../icons/ArrowDown';
import { useAppDispatch } from 'store';
import { getBannersThunk } from 'slices/general/getBannersReducer';
import { getCommonThunk } from 'slices/general/getCommonReducer';
import { updateLanguageApi } from 'apis/auth';

const languageOptions: string[] = ['en', 'fr'];

const LanguagePopover: React.FC = () => {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: string) => {
    //i18n.changeLanguage(language);
    i18n.changeLanguage(language).then(() => reloadCommonData());
    //updateLanguageApi({ language }).then(() => reloadCommonData());
    updateLanguageApi({ language });
    //dispatch(getBannersThunk()); 
    //dispatch(getCommonThunk());
    setOpen(false);
  };

  const reloadCommonData = () => {
    dispatch(getBannersThunk());
    dispatch(getCommonThunk());
  };
  const selectedOption = useMemo(() => {
    if (languageOptions.includes(i18n.resolvedLanguage)) {
      i18n.changeLanguage(i18n.resolvedLanguage);
      return i18n.resolvedLanguage;
    }
    i18n.changeLanguage('en');
    return 'en';
  }, [i18n.resolvedLanguage]);

  return (
    <>
      <Button
        size="small"
        onClick={handleOpen}
        ref={anchorRef}
        startIcon={<GlobeIcon sx={{ '@media screen and (max-width: 440px)':{ display: 'none' } }} />}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        sx={{
          height: '48px',
          paddingX: 1,
          '@media screen and (max-width: 767px)': {
            padding: '0',
            position: 'absolute',
            right: '-16px',
          },
        }}
      >
        <Box sx={{ width: 32 }}>
          <Typography
            sx={{
              color: 'black',
            }}
            variant="subtitle1"
          >
            {selectedOption}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingTop: '16px',
          }}
        >
          <ArrowDownIcon sx={{ fontSize: '20px' }} />
        </Box>
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 70 },
        }}
      >
        {languageOptions.map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemText
              primary={
                <Typography
                  sx={{
                    color: 'black',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle1"
                >
                  {language}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
