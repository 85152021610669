import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { connectToVendorApi, SendConnectionParams } from 'apis/matchedVendor';
import { CONNECTION_STATUS } from '../../constants';
import { ProjectState } from './type';

export const connectToVendorThunk = createAsyncThunk(
  'vendor/connectToVendor',
  async (params: SendConnectionParams) => {
    const response = await connectToVendorApi(params);
    return response;
  }
);

export const connectToVendorReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(connectToVendorThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(connectToVendorThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    console.log(payload);
    if (state.matchedVendor) {
      state.matchedVendor = {
        ...state.matchedVendor,
        isConnectable: false,
        matched: {
          status: CONNECTION_STATUS.WaitingForVendorResponse,
        },
      };
    }
    console.log(state.matchedVendor);
  });
  builder.addCase(connectToVendorThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
