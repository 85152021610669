import React from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@mui/material';

type Props = {
  loading?: boolean;
};

const Button: React.FC<ButtonProps & Props> = ({
  loading,
  sx = {},
  children,
  ...other
}) => (
  <MuiButton
    variant="contained"
    {...other}
    disableElevation
    sx={{ ...sx, pointerEvents: loading ? 'none' : 'inherit' }}
    startIcon={
      loading && (
        <CircularProgress size={16} sx={{ color: 'inherit', marginRight: 1 }} />
      )
    }
  >
    {children}
  </MuiButton>
);

export default Button;
