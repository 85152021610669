import { createSlice } from '@reduxjs/toolkit';
import { EndUserState } from './type';
import { getEndUsersReducer } from './getEndUsersReducer';
import { getPopularEndUsersReducer } from './getPopularEndUsersReducer';
import { createIndustryReducer } from './createEndUserReducer';

export const initialState: EndUserState = {
  isLoading: false,
  isProcessing: false,
  endUsers: undefined,
  popularEndUsers: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'endUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getEndUsersReducer(builder);
    getPopularEndUsersReducer(builder);
    createIndustryReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
