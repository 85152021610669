import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getPopularEndUsersApi } from 'apis/endUser';
import { EndUserState } from './type';

export const getPopularEndUsersThunk = createAsyncThunk(
  'endUser/getPopularEndUsers',
  async () => {
    const { data } = await getPopularEndUsersApi();
    return data.filter((item) => item.attributes.popular);
  }
);

export const getPopularEndUsersReducer = (
  builder: ActionReducerMapBuilder<EndUserState>
): void => {
  builder.addCase(getPopularEndUsersThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getPopularEndUsersThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.popularEndUsers = payload;
  });
  builder.addCase(getPopularEndUsersThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
