import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import NavItem from './NavItem';
import NavDropDownItem from './NavDropDownItem';
import { useTranslation } from 'react-i18next';

// @ts-ignore
const computeNavItems = (t: ReturnType<typeof useTranslation>['t']) => {
  return [
    { text: t('navigation.about'), pathname: '/#about' },
    { text: t('navigation.forEducators'), pathname: '/#howitworks' },
    { text: t('navigation.forProfessionals'), pathname: '/#vendors' },
    { text: t('navigation.impact'), pathname: '/#impact' },
    {
      text: t('navigation.Others'),
      pathname: '/',
      dropdown: true,
      dropdownProps: [
        {
          text: t('navigation.Others'),
          path: 'https://www.ecampusontario.ca/ontarioexchange/',
        },
        {
          text: t('navigation.FAQ'),
          path: 'https://www.ecampusontario.ca/ontarioexchange/faq',
        },
        {
          text: t('navigation.GetStarted'),
          path: 'https://www.ecampusontario.ca/ontarioexchange//getting-started/',
        },
      ],
    },
    {
      text: t('navigation.ContactUs'),
      pathname: 'mailto:exchange@ecampusontario.ca',
    },
  ];
};

const MainNavbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navItems = React.useMemo(() => {
    return computeNavItems(t);
  }, [t]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        marginX: 1,
      }}
    >
      {navItems.map((item) => (
        <React.Fragment>
          {item.dropdown ? (
            <NavDropDownItem
              dropdownProps={item.dropdownProps}
              key={item.pathname}
              pathname={item.pathname}
              active={
                Boolean(location.hash) && item.pathname.endsWith(location.hash)
              }
            >
              <h4>{item.text}</h4>
            </NavDropDownItem>
          ) : (
            <NavItem
              key={item.pathname + 1}
              pathname={item.pathname}
              active={
                Boolean(location.hash) && item.pathname.endsWith(location.hash)
              }
            >
              <h4>{item.text}</h4>
            </NavItem>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};
export default MainNavbar;
