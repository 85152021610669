import React, { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { initializeThunk } from 'slices/auth/initializeReducer';
import SignupModal from './SignupModal';
import LoginModal from './LoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';

const AuthContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(initializeThunk());
  }, []);

  return (
    <>
      {children}
      <SignupModal />
      <LoginModal />
      <ForgotPasswordModal />
    </>
  );
};

export default AuthContainer;
