import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { forgotPasswordApi } from 'apis/auth';
import { AuthState } from './type';

export const forgotPasswordThunk = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string) => {
    const response = await forgotPasswordApi({ email });
    return response;
  }
);

export const forgotPasswordReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(forgotPasswordThunk.pending, (state, action) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(forgotPasswordThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(forgotPasswordThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
