import 'nprogress/nprogress.css';
import './styles/styles.scss';
import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { createCustomTheme } from './theme';

const theme = createCustomTheme({
  responsiveFontSizes: true,
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <React.StrictMode>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Toaster position="top-center" />
                <App />
              </ThemeProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ReduxProvider>
      </HelmetProvider>
    </React.StrictMode>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
