import { createSlice } from '@reduxjs/toolkit';
import { GeneralState } from './type';
import { getCommonReducer } from './getCommonReducer';
import { getBannersReducer } from './getBannersReducer';
export const initialState: GeneralState = {
  isLoading: false,
  commonDefination: undefined,
  banners: [],
  error: undefined,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCommonReducer(builder);
    getBannersReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
