import { createSlice } from '@reduxjs/toolkit';
import { ReportState } from './type';
import { getMonthlyReportsReducer } from '../report/getMonthlyReportsReducer';
import { PROJECT_PAGE_SIZE } from 'constants/index';

export const initialState: ReportState = {
  isLoading: false,
  isProcessing: false,
  error: undefined,
  monthlyReports: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: PROJECT_PAGE_SIZE,
        pageCount: 0,
        total: 0,
      },
    },
  },
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getMonthlyReportsReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;
