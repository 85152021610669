import { createSlice } from '@reduxjs/toolkit';
import { SkillState } from './type';
import { createSkillReducer } from './createSkillReducer';

export const initialState: SkillState = {
  isLoading: false,
  isProcessing: false,
  skills: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'skill',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createSkillReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
