import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectDetailWithStatusApi } from 'apis/project';
import { ProjectState } from './type';

export const getProjectDetailWithStatusThunk = createAsyncThunk(
  'project/getProjectDetailWithStatus',
  async (id: number) => {
    const { data } = await getProjectDetailWithStatusApi(id);
    return data;
  }
);

export const getProjectDetailWithStatusReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(getProjectDetailWithStatusThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
    state.projectDetail = undefined;
    state.projectConnection = undefined;
  });
  builder.addCase(
    getProjectDetailWithStatusThunk.fulfilled,
    (state, { payload }) => {
      state.isLoading = false;
      state.projectDetail = payload.attributes.project.data;
      state.projectConnection = payload;
    }
  );
  builder.addCase(
    getProjectDetailWithStatusThunk.rejected,
    (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    }
  );
};
