import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { updatePortfolioApi, PortfolioParams } from 'apis/portfolio';
import { PortfolioState } from './type';

export const updatePortfolioThunk = createAsyncThunk(
  'portfolio/updatePortfolio',
  async ({ id, data }: { id: number; data: PortfolioParams }) => {
    const portfolio = await updatePortfolioApi(id, data);
    return portfolio.data;
  }
);

export const updatePortfolioReducer = (
  builder: ActionReducerMapBuilder<PortfolioState>
): void => {
  builder.addCase(updatePortfolioThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(updatePortfolioThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(updatePortfolioThunk.rejected, (state, action) => {
    state.isProcessing = false;
    state.error = action.error?.message;
  });
};
