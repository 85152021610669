import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMatchedVendorsApi,
  GetMatchedVendorsParams,
} from 'apis/matchedVendor';
import { ProjectState } from './type';

export const getMatchedVendorsThunk = createAsyncThunk(
  'project/getMatchedVendors',
  async ({ id, params }: { id: number; params: GetMatchedVendorsParams }) => {
    const response = await getMatchedVendorsApi(id, params);
    return response;
  }
);

export const getMatchedVendorsReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(getMatchedVendorsThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getMatchedVendorsThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.matchedVendors = payload;
  });
  builder.addCase(getMatchedVendorsThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
