/** configuration relate to UI */
import React from 'react';
import { useRoutes } from 'react-router-dom';
import useScrollReset from './hooks/useScrollReset';
import { useSelector } from 'store';
import LoadingScreen from './components/LoadingScreen';
import SplashScreen from './components/SplashScreen';
import AuthContainer from './containers/auth';
import routes from './routes';

const App: React.FC = () => {
  const isInitialized = useSelector((state) => state.auth.isInitialized);
  const content = useRoutes(routes);
  useScrollReset();

  return (
    // using Suspense: wait for language json files loaded
    <React.Suspense fallback={<LoadingScreen />}>
      <AuthContainer>
        {isInitialized ? content : <SplashScreen />}
      </AuthContainer>
    </React.Suspense>
  );
};

export default App;
