import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { checkProjectTypeStatus } from 'apis/project';
import { ProjectState } from './type';

export const checkProjectTypeStatusThunk = createAsyncThunk(
  'projects/checkProjectType',
  async () => {
    const payload = await checkProjectTypeStatus();
    return payload;
  }
);

export const checkProjectTypeStatusReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(checkProjectTypeStatusThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(
    checkProjectTypeStatusThunk.fulfilled,
    (state, { payload }) => {
      state.isLoading = false;
      state.projectTypeStatus = payload.data;
    }
  );
  builder.addCase(checkProjectTypeStatusThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
