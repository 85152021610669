import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getPopularLanguagesApi } from 'apis/language';
import { LanguageState } from './type';

export const getPopularLanguagesThunk = createAsyncThunk(
  'language/getPopularLanguages',
  async () => {
    const { data } = await getPopularLanguagesApi();
    return data.filter((item) => item.attributes.popular);
  }
);

export const getPopularLanguagesReducer = (
  builder: ActionReducerMapBuilder<LanguageState>
): void => {
  builder.addCase(getPopularLanguagesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getPopularLanguagesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.popularLanguages = payload;
  });
  builder.addCase(getPopularLanguagesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
