import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getEndUsersApi } from 'apis/endUser';
import { EndUserState } from './type';

export const getEndUsersThunk = createAsyncThunk(
  'endUser/getEndUsers',
  async () => {
    const endUsers = await getEndUsersApi();
    return endUsers;
  }
);

export const getEndUsersReducer = (
  builder: ActionReducerMapBuilder<EndUserState>
): void => {
  builder.addCase(getEndUsersThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getEndUsersThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.endUsers = payload.data;
  });
  builder.addCase(getEndUsersThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
