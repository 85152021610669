import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createEndUserApi, CreateEndUserParams } from 'apis/endUser';
import { EndUserState } from './type';

export const createEndUserThunk = createAsyncThunk(
  'endUser/createEndUser',
  async (params: CreateEndUserParams) => {
    const { data } = await createEndUserApi(params);
    return data;
  }
);

export const createIndustryReducer = (
  builder: ActionReducerMapBuilder<EndUserState>
): void => {
  builder.addCase(createEndUserThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(createEndUserThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(createEndUserThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
