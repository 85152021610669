import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createSkillApi, CreateSkillParams } from 'apis/skill';
import { SkillState } from './type';

export const createSkillThunk = createAsyncThunk(
  'skill/createSkill',
  async (params: CreateSkillParams) => {
    const { data } = await createSkillApi(params);
    return data;
  }
);

export const createSkillReducer = (
  builder: ActionReducerMapBuilder<SkillState>
): void => {
  builder.addCase(createSkillThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(createSkillThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
  });
  builder.addCase(createSkillThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
