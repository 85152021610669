import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationParam } from 'apis/base';
import { getMatchedProjectsApi } from 'apis/project';
import { ProjectState } from './type';

export const getVendorMatchedProjectsThunk = createAsyncThunk(
  'project/getVendorMatchedProjects',
  async (params: PaginationParam) => {
    const response = await getMatchedProjectsApi(params);
    return response;
  }
);

export const getVendorMatchedProjectsReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(getVendorMatchedProjectsThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(
    getVendorMatchedProjectsThunk.fulfilled,
    (state, { payload }) => {
      state.isLoading = false;
      state.vendorProjects = payload;
    }
  );
  builder.addCase(
    getVendorMatchedProjectsThunk.rejected,
    (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    }
  );
};
