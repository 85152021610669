import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getProvincesApi } from 'apis/province';
import { ProvinceState } from './type';

export const getProvincesThunk = createAsyncThunk(
  'province/getProvinces',
  async () => {
    const provinces = await getProvincesApi();
    return provinces;
  }
);

export const getProvincesReducer = (
  builder: ActionReducerMapBuilder<ProvinceState>
): void => {
  builder.addCase(getProvincesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getProvincesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.provinces = payload.data;
  });
  builder.addCase(getProvincesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
