import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  UpdateProjectMatchedStatusAttributes,
  updateProjectMatchedStatusApi,
} from 'apis/project';
import { ProjectState } from './type';

export const updateProjectMatchedStatusThunk = createAsyncThunk(
  'project/updateProjectMatchedStatus',
  async (params: UpdateProjectMatchedStatusAttributes) => {
    const { data } = await updateProjectMatchedStatusApi(params);
    return data;
  }
);

export const updateProjectMatchedStatusReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(updateProjectMatchedStatusThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });

  builder.addCase(updateProjectMatchedStatusThunk.fulfilled, (state) => {
    state.isProcessing = false;
  });

  builder.addCase(
    updateProjectMatchedStatusThunk.rejected,
    (state, { error }) => {
      state.isProcessing = false;
      state.error = error.message;
    }
  );
};
