import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getLanguagesApi } from 'apis/language';
import { LanguageState } from './type';

export const getLanguagesThunk = createAsyncThunk(
  'language/getLanguages',
  async () => {
    const languages = await getLanguagesApi();
    return languages;
  }
);

export const getLanguagesReducer = (
  builder: ActionReducerMapBuilder<LanguageState>
): void => {
  builder.addCase(getLanguagesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getLanguagesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.languages = payload.data;
  });
  builder.addCase(getLanguagesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
