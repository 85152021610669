import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'store';
import { routesConstant } from '../../constants/routeConstant';

interface Props {
  children?: React.ReactNode;
}

const GuestGuard: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (isAuthenticated) {
    return <Navigate to={routesConstant.dashboard} />;
  }

  return <>{children || <Outlet />}</>;
};

export default GuestGuard;
