import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationParam } from 'apis/base';
import { getPortfoliosApi, getPortfoliosByVendorIdApi } from 'apis/portfolio';
import { PortfolioState } from './type';

export const getPortfoliosThunk = createAsyncThunk(
  'portfolio/getPortfolios',
  async ({ vendorId, ...param }: PaginationParam & { vendorId?: number }) => {
    const api = !vendorId ? getPortfoliosApi : getPortfoliosByVendorIdApi;
    const response = await api({ ...param, vendorId: vendorId as number });
    return response;
  }
);

export const getPortfoliosReducer = (
  builder: ActionReducerMapBuilder<PortfolioState>
): void => {
  builder.addCase(getPortfoliosThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getPortfoliosThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.portfolios = payload;
  });
  builder.addCase(getPortfoliosThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
