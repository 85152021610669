import { SvgIcon } from '@mui/material';

const Icon = () => (
  <SvgIcon viewBox="0 0 16 16" sx={{ width: 16, height: 16 }}>
    <path
      d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z"
      fill="#3C6670"
    />
  </SvgIcon>
);

export default Icon;
