export const primary = '#1E1A34';
export const secondary = '#3C6670';
export const secondaryLight = '#497C89';
export const tertiary = '#F1B434';
export const tertiaryLanding = '#FCB53B';
export const primaryText = '#181A1B';
export const secondaryText = '#858997';
export const disabledText = '#AEB1BA';

export const white = '#FFFFFF';
export const lightGrey = '#F2EFF6';
export const grey2 = '#E9E8EB';
export const grey3 = '#C7C6CC';
export const grey4 = '#8E8C99';
export const grey5 = '#565367';
export const grey50 = '#F9FAFB';
export const grey500 = '#667085';
export const greyOutline = '#D6D8DD';

export const border = grey3;
export const outlineButtonBorder = grey3;

export const backgroundDefault = 'rgb(250, 250, 250)';

export const error = '#f44336';
export const success = '#4caf50';
export const darkSuccess = '#76881D';
export const warning = '#ff9800';

export const tagBg = '#F4F4F5';

export const blue50 = '#EFF8FF';
export const blue700 = '#175CD3';

export const primary50 = '#F9F5FF';
export const primary700 = '#6941C6';

export const systemSuccessLight = '#EAFDF3';
export const systemSuccess = '#257846';

export const checkedCheckbox = '#42878C';
