import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { updateRatesApi, UpdateRatesParams } from 'apis/auth';
import { AuthState } from './type';

// https://redux-toolkit.js.org/api/createAsyncThunk
// a failed request or error in a thunk will never return a rejected promise
// If your component needs to know if the request failed, use .unwrap
// by default reject error will be populate in action.error
// Doing return rejectWithValue(errorPayload) will cause the rejected action to use that value as action.payload
export const updateRatesThunk = createAsyncThunk(
  'auth/updateRates',
  async (parmas: UpdateRatesParams) => {
    const { user } = await updateRatesApi(parmas);
    return user;
  }
);

export const updateRatesReducer = (
  builder: ActionReducerMapBuilder<AuthState>
): void => {
  builder.addCase(updateRatesThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(updateRatesThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    state.openSignupModal = false;
    state.user = { ...state.user, ...payload };
  });
  builder.addCase(updateRatesThunk.rejected, (state, action) => {
    state.isProcessing = false;
    state.error = action.error?.message;
  });
};
