import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createPortfolioApi, PortfolioParams } from 'apis/portfolio';
import { PortfolioState } from './type';

export const createPortfolioThunk = createAsyncThunk(
  'portfolio/createPortfolio',
  async (params: PortfolioParams) => {
    const { data } = await createPortfolioApi(params);
    return data;
  }
);

export const createPortfolioReducer = (
  builder: ActionReducerMapBuilder<PortfolioState>
): void => {
  builder.addCase(createPortfolioThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });

  builder.addCase(createPortfolioThunk.fulfilled, (state) => {
    state.isProcessing = false;
  });

  builder.addCase(createPortfolioThunk.rejected, (state, { error }) => {
    state.isProcessing = false;
    state.error = error.message;
  });
};
