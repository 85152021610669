import { createSlice } from '@reduxjs/toolkit';
import { LanguageState } from './type';
import { getLanguagesReducer } from './getLanguagesReducer';
import { getPopularLanguagesReducer } from './getPopularLanguagesReducer';
import { createLanguageReducer } from './createLanguageReducer';

export const initialState: LanguageState = {
  isLoading: false,
  isProcessing: false,
  languages: undefined,
  popularLanguages: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getLanguagesReducer(builder);
    getPopularLanguagesReducer(builder);
    createLanguageReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
