import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createProjectApi } from 'apis/project';
import { createIndustryApi } from 'apis/industry';
import { createEndUserApi } from 'apis/endUser';
import { ProjectState } from './type';
import { RootState } from '../../store/rootReducer';

export const createProjectThunk = createAsyncThunk(
  'project/createProject',
  async (args, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const createProjectPayload = state.project.createProjectPayload;
    if (
      createProjectPayload &&
      createProjectPayload.projectType &&
      createProjectPayload.lead &&
      createProjectPayload.details &&
      createProjectPayload.match &&
      createProjectPayload.confirmation
    ) {
      const lead = createProjectPayload.lead;
      const {
        otherEndUserName,
        endUsers,
        subjectOption,
        industry: industryOption,
        otherIndustry,
        ...details
      } = createProjectPayload.details;
      const {
        chosenServices,
        otherService,
        addedService,
        totalHours,
        ...match
      } = createProjectPayload.match;

      const chosenServicesList = Object.values(chosenServices).filter(
        (service) => service.chosen
      );

      // handle industry
      let industry = industryOption;
      if (otherIndustry) {
        const response = await createIndustryApi({ name: otherIndustry });
        industry = response.data.id;
      }

      const otherEndUser = [];
      if (otherEndUserName) {
        const { data } = await createEndUserApi({ name: otherEndUserName });
        otherEndUser.push(data.id);
      }

      const params = {
        projectType: createProjectPayload.projectType,
        ...lead,
        ...details,
        ...match,
        industry,
        end_users: [...endUsers, ...otherEndUser],
        services: chosenServicesList.map((item) => item.id),
        table: {
          data: chosenServicesList.map((service) => {
            const { chosen, ...rest } = service;
            return rest;
          }),
          totalHours: { ...totalHours },
        },
      };

      const { data } = await createProjectApi(params);
      return data;
    } else {
      return rejectWithValue('Missing required data.');
    }
  }
);

export const createProjectReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(createProjectThunk.pending, (state) => {
    state.isProcessing = true;
    state.error = undefined;
  });
  builder.addCase(createProjectThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    state.createProjectPayload = {};
  });
  builder.addCase(createProjectThunk.rejected, (state, { error, payload }) => {
    state.isProcessing = false;
    state.error = (payload as string) || error.message;
  });
};
