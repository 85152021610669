import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getPopularServicesApi } from 'apis/service';
import { ServiceState } from './type';

export const getPopularServicesThunk = createAsyncThunk(
  'service/getPopularServices',
  async () => {
    const { data } = await getPopularServicesApi();
    return data.filter((item) => item.attributes.popular);
  }
);

export const getPopularServicesReducer = (
  builder: ActionReducerMapBuilder<ServiceState>
): void => {
  builder.addCase(getPopularServicesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getPopularServicesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.popularServices = payload;
  });
  builder.addCase(getPopularServicesThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
