import api from './base';

export interface Option {
  value: string;
  label: string;
}

export interface CommonDefination {
  organizationTypeList: Option[];
  companySizeList: Option[];
  serviceExperienceLevelList: Option[];
  languageLevelList: Option[];
  availableBudgets: Option[];
  projectTypes: Option[];
  matchedStatus: Option[];
  languages: { id: number; name: string; code: string }[];
  institutionNames: { value: string; label: string; options: Option[] }[];
  projectStatus: Option[];
  projectDeliverableStatus: Option[];
  isEnableLearnerExperience: boolean;
}
export type BannerApplyFor = 'vendor' | 'institution';
export interface Banner {
  id: number;
  attributes: {
    title: string;
    description?: string;
    applyFor: BannerApplyFor;
    show: boolean;
    buttonText?: string;
    buttonLink?: string;
  };
}
export const getCommonDefinationApi = async () =>
  api.get('/common-define') as Promise<{ data: CommonDefination }>;
export const getBannersApi = async () =>
  api.post('/banners/get-banners') as Promise<{ data: Banner[] }>;
