import api, { ListResponse } from './base';

export interface Software {
  id: number;
  attributes: { name: string; popular: boolean };
}

export const getSoftwaresApi = async () =>
  api.get('/softwares') as Promise<ListResponse<Software>>;

export const getAllSoftwaresApi = async () =>
  api.post('/softwares/all') as Promise<ListResponse<Software>>;
